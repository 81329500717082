<template>
  <div id="searchValue">
    <div class="searchValue_in">
      <detailheader></detailheader>
      <div class="searchInput">
        <div class="inputbtn">
          <el-input v-model="searchvalue" @keyup.enter.native="search"></el-input>
          <el-button type="primary" style="margin-left:20px;" @click="search">搜索</el-button>
        </div>
        <div class="keyword">
          <div class="keywordtitle">热门搜索:</div>
          <ul class="keywordlist">
            <li
              v-for="(item, index) in hotsearchs"
              :key="index"
              @click="hotsearch(item.Keyword)"
            >
              {{ item.Keyword }}
            </li>
          </ul>
        </div>
      </div>
      <div class="new_menu">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">产品</el-menu-item>
          <el-menu-item index="2">新闻资讯</el-menu-item>
        </el-menu>
      </div>
      <div class="show-result-info">共计{{ total }}结果</div>
      <div class="product" v-if="activeIndex == '2'">
        <div style="margin: 0 auto">
          <div class="news_list" v-if="newsList.length != 0">
            <div
              class="news_single_info"
              v-for="(item, index) in newsList"
              :key="index"
              @click="toNewsDetail(index)"
            >
              <div
                :class="
                  item.Typesetting == 1
                    ? 'news_detail_img'
                    : item.Typesetting == 2
                    ? 'news_detail_img_small'
                    : 'news_detail_img_low'
                "
              >
                <div class="news_detail_img_in">
                  <img
                    v-for="(item_in, index_in) in newsList[index].CoverImgUrl"
                    :key="index_in"
                    :src="imgUrlFront + item_in"
                    alt="找不到图片"
                  />
                </div>
              </div>
              <div class="news_detail_info">
                <p class="news_detail_info_data">
                  {{ item.PublishTimeDisplay }}
                </p>
                <p class="news_detail_info_kind">
                  <i
                    v-for="(itemin, index) in newsList[index].Tags"
                    :key="index"
                  >
                    {{ itemin }}
                  </i>
                </p>
                <h6>{{ item.ArticleTitle }}</h6>
                <span class="readmore">阅读更多</span>
              </div>
            </div>
          </div>
          <div class="paging" v-if="newsList.length != 0">
            <el-pagination
              background
              layout="prev, pager, next"
              @prev-click="prevclick"
              @next-click="nextclick"
              @current-change="currentclick"
              :current-page="currentPage"
              :total="total"
              :page-size="pageSize"
            >
            </el-pagination>
          </div>
          <div class="nodata" v-if="newsList.length == 0">
            <img src="~/assets/images/empty.png" alt="">
          </div>
        </div>
      </div>
      <div class="news" v-if="activeIndex == '1'">
        <div class="product_page" v-if="!productList.length == 0">
          <div
            class="product_info"
            v-for="(item, index) in productList"
            :key="index"
            @mouseenter="enter_arrow_Selected(index)"
            @mouseleave="leave_arrow_Selected()"
            @click="toProdcutDetail(index)"
          >
            <div class="product_info_img">
              <img :src="imgUrlFront + item.ListImageUrl" alt="找不到资源" />
            </div>
            <div class="product_info_intro">
              <h5>{{ item.ServiceItemName }}</h5>
              <p>{{ item.Description }}</p>
              <img
                :src="currentIndex == index ? arrow_Selected : arrow_pic"
                alt="找不到资源"
              />
            </div>
          </div>
        </div>
        <div class="zhanwei" v-if="!productList.length == 0"></div>
        <div class="nodata" v-if="productList.length == 0">
          <img src="~/assets/images/empty.png" alt="">
        </div>
      </div>
      <footer-page></footer-page>
    </div>
  </div>
</template>

<script>
import detailheader from "@/components/content/detail/detailheader.vue";
import footerPage from "@/components/common/footer/footerPage.vue";
import { setSession, getSession } from "@/utils/utils.js";
export default {
  name: "searchValue",
  data() {
    return {
      hotsearchs: [], //热门搜索关键词
      activeIndex: "1", //tab栏切换索引组件默认String
      imgUrlFront: window.sessionStorage.getItem("apiImgUrl"), //图片前缀url
      total: "", //返回结果总数
      newsList: "", //返回新闻列表
      currentPage: 1, //当前页
      pageSize: 9, //一页数量
      searchvalue: "", //搜索得值
      // showNone: 0, //无数据页面默认不显示
      productList: "", //返回产品列表
      currentIndex: null,
      arrow_Selected: require("@/assets/images/product/enter_arrow_selected.png"),
      arrow_pic: require("@/assets/images/product/enter_arrow.png"),
    };
  },
  components: {
    detailheader,
    footerPage,
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    issearchclick() {
      return this.$store.state.issearchclick;
    },
  },
  created() {
    this.$store.commit("issearchclick", false);
    this.init();
    this.gethotsearch();
  },
  mounted() {},
  watch: {},
  methods: {
    //初始化搜索详情
    init() {
      this.searchvalue = this.$route.query.searchvalue;
      this.newsList = JSON.parse(this.$route.query.newsdata);
      this.newsList.forEach((item) => {
        item.ArticleTitle = decodeURIComponent(item.ArticleTitle);
      });
      this.productList = JSON.parse(this.$route.query.items);
      this.total = parseInt(this.$route.query.total) + this.productList.length;
    },
    gethotsearch() {
      let request = {
        Version: "",
        JZToken: "",
        UserGuid: "",
      };
      this.$axios("/API/Get_SearchRecordAndHotKeywords", request).then(
        (res) => {
          this.hotsearchs = res.hotKeywords;
        }
      );
    },
    //点击热词搜索
    hotsearch(value) {
      this.searchvalue = value;
      this.currentPage = 1
      let request = {
        Version: "",
        KeyWord: value,
        PageIndex: this.currentPage,
        PageSize: this.pageSize,
        JZToken: "",
        UserGuid: "",
      };
      this.$axios("/API/SearchContent", request).then((res) => {
        res.data.forEach((item) => {
          item.ArticleTitle = decodeURIComponent(item.ArticleTitle);
          item.CoverImgUrl = item.CoverImgUrl.split(",", 2);
          item.Tags = item.Tags.split(",");
        });
        this.newsList = res.data;
        this.productList = res.items;
        this.total = parseInt(res.count) + res.items.length;
        if(res.items.length == 0){
          if(res.data.length != 0){
            this.activeIndex = "2"
          }
        }
        setSession("searchresult", res);
      });
    },
    //搜索
    search() {
      let that =this
      window.sessionStorage.setItem("searchvalue", this.searchvalue);
      new Promise((resolve, reject) => {
        let request = {
          Version: "",
          KeyWord: this.searchvalue,
          PageIndex: this.currentPage,
          PageSize: this.pageSize,
          JZToken: "",
          UserGuid: "",
        };
        this.$axios("/API/SearchContent", request).then((res) => {
          res.data.forEach((item) => {
			      item.ArticleTitle = decodeURIComponent(item.ArticleTitle)
            item.CoverImgUrl = item.CoverImgUrl.split(",", 2);
            item.Tags = item.Tags.split(",");
          });

          this.newsList = res.data;
          this.productList = res.items;
          this.total = parseInt(res.count) + res.items.length;
          if(res.items.length == 0){
            if(res.data.length != 0){
              this.activeIndex = "2"
            }
          }

          setSession("searchresult", res);
        });
      }).then((resolve) => {
        let result = resolve;
      });
    },

    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
    //跳转至详情页
    toNewsDetail(index) {
	  let flnkid = this.newsList[index].FLnkID
      this.$router.push({
        name: "newsdetail",
        query: {
          newsflnkid : flnkid,
        },
      });
    },
    //跳转至产品页
    toProdcutDetail(index) {
      this.$router.push({
        path: "/prodcut/productdetail",
        query: {
          productobj: JSON.stringify(this.productList[index]),
        },
      });
    },
    //上一页
    prevclick() {
      this.currentPage -= 1;
      this.getArticle(this.currentPage);
    },
    //下一页
    nextclick() {
      this.currentPage += 1;
      this.getArticle(this.currentPage);
    },
    //页码改变，当前页
    currentclick(e) {
      let that = this;
      that.currentPage = e;
      that.getArticle(this.currentPage);
    },
    getArticle(currentPage) {
      let newsList = [];
      this.$axios("/API/SearchContent", {
        Version: "",
        KeyWord: this.searchvalue,
        PageIndex: this.currentPage,
        PageSize: this.pageSize
      }).then((res) => {
        res.data.forEach((item) => {
          item.ArticleTitle = decodeURIComponent(item.ArticleTitle);
          item.CoverImgUrl = item.CoverImgUrl.split(",", 2);
          item.Tags = item.Tags.split(",");
          newsList.push(item);
        });
        this.newsList = newsList;
      });
    },
    enter_arrow_Selected(index) {
      this.currentIndex = index;
    },
    leave_arrow_Selected() {
      this.currentIndex = null;
    },
  },
  watch: {
    issearchclick: {
      handler(old, newvalue) {
        let keyword = this.$store.state.searchvalue;
        this.searchvalue = this.$store.state.searchvalue;
        let request = {
          Version: "",
          KeyWord: keyword,
          PageIndex: this.currentPage,
          PageSize: this.pageSize,
          JZToken: "",
          UserGuid: "",
        }
        this.$axios("/API/SearchContent", request).then((res) => {
          res.data.forEach((item) => {
			      item.ArticleTitle = decodeURIComponent(item.ArticleTitle)
            item.CoverImgUrl = item.CoverImgUrl.split(",", 2);
            item.Tags = item.Tags.split(",");
          });
          this.$store.commit("issearchclick", false);
          this.newsList = res.data;
          this.productList = res.items;
          this.total = parseInt(res.count) + res.items.length;
          if(res.items.length == 0){
            if(res.data.length != 0){
              this.activeIndex = "2"
              console.log(this.activeIndex)
            }
          }

          // setSession("searchresult", res);
        });
      },
    },
  },
  destroyed() {
    window.sessionStorage.removeItem("searchvalue");
  },
};
</script>

<style lang="scss" scoped>
@media(min-width: 1201px){
  @import '@/pages/search/searchValue.scss';
}
@media(max-width: 1200px){
  @import '@/pages/search/searchValue_phone.scss';
}
</style>
